const theme = {}

export const colors = {
  primary: '#c48a43',
  primaryDull: '#bb9c77',
  secondary: '#fff',
  textPrimary: '#413f3f',
  textSecondary: '#57585a',
  textDescription: '#57585a',
}

export default theme
