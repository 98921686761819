import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Typography from 'components/Typography'
import MenuItem from 'components/MenuItem'

import { investors } from './FooterData'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    '@media (max-width: 425px)': {
      marginBottom: 16,
    },
  },
  label: {
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 16,
    marginTop: 0,
  },
  description2: {
    marginBottom: 16,
    '@media (max-width: 768px)': {
      fontSize: 12,
    },
  },
})

const Logo = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Typography size={16} variant="body2" color="primary" classes={{ root: classes.description2 }}>
        Investors
      </Typography>
      {
        investors.map((x) => (
          <MenuItem key={x.id} link={x.link} labelStyles={classes.label}>{x.label}</MenuItem>
        ))
      }
    </div>
  )
}

export default Logo
