import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  root: {
    boxShadow: 'none',
    transition: 'box-shadow 150ms ease-in',
    '&:hover': {
      boxShadow: '0 10px 30px 0 rgba(0, 0, 0, 0.08)',
    },
  },
})

const CardHover = (props) => {
  const { disabled } = props
  const classes = useStyles()

  return (
    <div className={clsx({ [classes.root]: !disabled })}>{props.children}</div>
  )
}

CardHover.propTypes = {}
CardHover.defaultProps = {}

export default CardHover
