// maps the rich text content blocks to a custom component

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import LazyImage from 'components/LazyImage'
import Typography from 'components/Typography'
import { colors } from 'theme'

const useH6Styles = makeStyles(theme => ({
  wrapper: {
    borderLeft: `1px solid ${colors.primary}`,
    paddingLeft: 38,
    marginTop: -16,
    [theme.breakpoints.down('sm')]: {
      padding: '0px',
      borderLeft: 'none',
    },
  },
  text: {
    fontFamily: 'CenturySchool',
    fontStyle: 'italic',
    fontSize: 20,
    opacity: 0.4,
  },
}))

export const HEADING_6 = (props) => {
  const { children } = props
  const classes = useH6Styles()

  return (
    <div className={classes.wrapper}>
      <Typography className={classes.text}>
        {children}
      </Typography>
    </div>
  )
}

const useBodyStyles = makeStyles(theme => ({
  body: {
    fontWeight: 300,
    lineHeight: 2.5,
  },
}))

export const BODY = (props) => {
  const { children } = props
  const classes = useBodyStyles()

  return (
    <Typography className={classes.body}>{children}</Typography>
  )
}

const useAssetStyles = makeStyles(theme => ({
  image: {
    margin: '32px 0px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: -30,
      marginRight: -30,
    },
  }
}))

export const IMAGE = (props) => {
  const { src, alt } = props
  const classes = useAssetStyles()

  return (
    <div className={classes.image}>
      <LazyImage src={src} alt={alt} />
    </div>
  )
}

export default {
  HEADING_6,
  BODY,
  IMAGE,
}
