import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'

const styles = {
  container: {
    width: '100%',
  },
}

const useStyles = makeStyles({
  tradeViewText: {
    color: '#333',
    fontSize: 12,
  },
})

const StockPrice = (props) => {
  const [pageLoaded, setPageLoaded] = useState(false)
  const classes = useStyles()

  const loadWidget = () => {
    new window.TradingView.MediumWidget({ // eslint-disable-line
      container_id: 'tv-medium-widget',
      symbols: [
        [
          'Kadestone Capital Corp',
          'TSXV:KDSX|1m',
        ],
      ],
      greyText: 'Quotes by',
      gridLineColor: '#e9e9ea',
      fontColor: '#83888D',
      underLineColor: '#bb9c77',
      trendLineColor: '#c48a43',
      width: '100%',
      height: '400',
      locale: 'en',
    })
  }

  const onPageLoad = () => {
    setPageLoaded(true)
  }

  useEffect(() => {
    window.addEventListener('load', onPageLoad)

    return () => {
      window.removeEventListener('load', onPageLoad)
    }
  }, [])

  useEffect(() => {
    loadWidget()
    console.log('loaded widget')
  }, [pageLoaded])

  return (
    <div style={styles.container} className="tradingview-widget-container">
      <div id="tv-medium-widget" />
      <div className="tradingview-widget-copyright"><a href="https://www.tradingview.com/symbols/TSXV-KDSX/" rel="noopener" target="_blank"><span className={classes.tradeViewText}>Kadestone Capital Corp. Quotes by TradingView</span></a></div>
    </div>
  )
}

export default StockPrice
