import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Pagination from '@material-ui/lab/Pagination'
import Typography from 'components/Typography'
import BlogListItem from './BlogListItem'

const useStyles = makeStyles((theme) => ({
  header: {
    margin: '40px 0px',
    fontSize: 20,
  },
  selectedItem: {
    '& button.Mui-selected': {
      backgroundColor: '#f4ece2',
    },
  },
}))

const POSTS_PER_PAGE = 5

const RecentBlogPosts = (props) => {
  const { posts } = props
  const [page, setPage] = useState(1)
  const classes = useStyles()
  const matchesTablet = useMediaQuery(theme => theme.breakpoints.down('sm'))

  const pages = Math.ceil(posts.length / POSTS_PER_PAGE)

  const postsOnCurrentPage = posts.slice((page - 1) * POSTS_PER_PAGE, page * POSTS_PER_PAGE)

  const onChange = (e, nextPage) => {
    setPage(nextPage)
    window.scrollTo(0, 0)
  }

  return (
    <div>
      <Typography className={classes.header} variant="h2">Recent Press</Typography>
      {
        postsOnCurrentPage.length
          ? (
            <>
              {
                postsOnCurrentPage.map((x, i) => (
                  <BlogListItem key={x.id} post={x} tabIndex={i + 1} />
                ))
              }
              <Grid container justify="center">
                <Pagination
                  count={pages}
                  page={page}
                  shape="rounded"
                  size="large"
                  onChange={onChange}
                  classes={{ root: classes.selectedItem }}
                />
              </Grid>
            </>
          )
          : <Typography weight="light">There aren&apos;t any posts right now!</Typography>
      }
    </div>
  )
}

RecentBlogPosts.propTypes = {}
RecentBlogPosts.defaultProps = {}

export default RecentBlogPosts
