import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Typography from 'components/Typography'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    marginTop: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
    borderTop: '1px solid #bb9c77',
    borderBottom: '1px solid #bb9c77',
    height: 70,
    marginBottom: 1,
    '@media (max-width: 425px)': {
      height: 70,
    },
  },
  description2: {
    padding: '10px 0',
    '@media (max-width: 425px)': {
      fontSize: 12,
      textAlign: 'center',
    },
  },
})

const Copyright = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Typography size={16} variant="body2" color="primary" classes={{ root: classes.description2 }}>
        ©{new Date().getFullYear()} Kadestone Capital Corp. All Rights Reserved. Powered and Designed by <a href="https://www.staccc.com" target="_blank" rel="noopener noreferrer">Staccc</a>.
      </Typography>
    </div>
  )
}

export default Copyright
