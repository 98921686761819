import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Tabs from 'components/Tabs'
import PageHero from 'components/PageHero'
import PageContentBox from 'components/PageContentBox'
import PageHeroImage from 'assets/images/slideshow2.jpg'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import AGM from './AGM'
import GovernmentDocuments from './GovernmentDocuments'
import FinancialResults from './FinancialResults'
import InvestorRelationsContact from './InvestorRelationsContact'
import MobileAccordion from './MobileAccordion'

const useStyles = makeStyles((theme) => ({
  page: {
    padding: '100px 0px',
    [theme.breakpoints.down('xs')]: {
      padding: '16px 30px 100px',
    },
    '@media (max-width: 320px)': {
      padding: '16px 0px 100px',
    },
  },
  mobilePage: {
    padding: '30px 0px 100px',
  },
}))

const Investors = (props) => {
  const classes = useStyles()
  const matchesTablet = useMediaQuery(theme => theme.breakpoints.down('sm'))

  const TabData = [{
    tabLabel: 'AGM DOCUMENTS',
    hash: 'agm-documents',
    tabBody: <div className={classes.page}><AGM /></div>,
  }, {
    tabLabel: 'CORPORATE GOVERNANCE DOCUMENTS',
    hash: 'governance-documents',
    tabBody: <div className={classes.page}><GovernmentDocuments /></div>,
  }, {
    tabLabel: 'FINANCIAL RESULTS',
    hash: 'financial-results',
    tabBody: <div className={classes.page}><FinancialResults /></div>,
  }, {
    tabLabel: 'INVESTOR RELATIONS CONTACT',
    hash: 'contact',
    tabBody: <div className={classes.page}><InvestorRelationsContact /></div>,
  }]

  const MobileTabData = [{
    tabLabel: 'INVESTMENT',
    hash: 'governance-documents',
    tabBody: (
      <div className={classes.mobilePage}>
        <MobileAccordion summary="AGM" details={<AGM />} />
        <MobileAccordion summary="Corporate Governance Documents" details={<GovernmentDocuments />} />
        <MobileAccordion summary="Financial Results" details={<FinancialResults />} />
      </div>
    ),
  }, {
    tabLabel: 'INVESTOR RELATIONS CONTACT',
    hash: 'contact',
    tabBody: <div className={classes.page}><InvestorRelationsContact /></div>,
  }]

  const MatchTabs = matchesTablet ? MobileTabData : TabData

  return (
    <div>
      {matchesTablet ? null : <PageHero image={PageHeroImage} title="Investors" />}
      <Tabs data={MatchTabs.map(x => ({ ...x, tabBody: <PageContentBox>{x.tabBody}</PageContentBox>}))} />
    </div>
  )
}

Investors.propTypes = {}
Investors.defaultProps = {}

export default Investors
