import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import BorderBox from 'components/BorderBox'
import Typography from 'components/Typography'
import ArrowForward from '@material-ui/icons/ArrowForward'
import IconButton from '@material-ui/core/IconButton'
import EstablistmentImage from 'assets/images/angledSquareBuildings.jpg'
import HomeHero from 'assets/images/homeHero.jpeg'
import TabPanel from './TabPanel'

const useStyles = makeStyles((theme) => ({
  gridRoot: {
    display: 'flex',
    background: '#fff',
  },
  root: {
    width: '100%',
    padding: '136px 85px 146px',
    maxWidth: 664,
    background: '#fff',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 500,
    },
    [theme.breakpoints.down('xs')]: {
      padding: '80px 30px 106px',
    },
  },
  description1: {
    lineHeight: 2,
    maxWidth: 400,
    margin: '56px 0 112px auto',
    [theme.breakpoints.down('sm')]: {
      margin: '56px 0 56px auto',
    },
  },
  description2: {
    display: 'inline-block',
    margin: '56px 0 0',
    lineHeight: 2,
    maxWidth: 320,
  },
  investmentButton: {
    margin: '0 0 180px 0',
    [theme.breakpoints.down('sm')]: {
      margin: '0 0 100px 0',
    },
  },
  description3: {
    maxWidth: 480,
    right: 48,
    position: 'absolute',
    bottom: 180,
    [theme.breakpoints.down('sm')]: {
      right: 24,
      maxWidth: '50%',
    },
    [theme.breakpoints.down(720)]: {
      maxWidth: 300,
      bottom: 120,
      right: 48,
    },
    [theme.breakpoints.down('xs')]: {
      bottom: 0,
      right: 0,
      maxWidth: 'unset',
      position: 'relative',
    },
  },
  description31: {
    lineHeight: 2,
    padding: '56px 0 0 56px',
    [theme.breakpoints.down('sm')]: {
      padding: '24px 0 0 24px',
    },
    [theme.breakpoints.down(720)]: {
      padding: '24px 0',
    },
  },
  growthImage: {
    height: 400,
    maxWidth: 'calc(50vw - 40px)',
    transform: 'rotateY(180deg)',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${HomeHero})`,
    margin: '0px -85px',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  establishmentImage: {
    flex: 1,
    height: 670,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${EstablistmentImage})`,
  },
}))

const AboutUs = (props) => {
  const classes = useStyles()

  return (
    <TabPanel>
      <BorderBox position="left" length={200}>
        <div className={classes.gridRoot}>
          <div className={classes.root}>
            <Typography variant="h2" color="primary" size={28}>Established in July 2019</Typography>
            <Typography classes={{ root: classes.description1 }}>
              Kadestone is a real estate investment and development company incorporated under the laws of the Province of British Columbia in July 2019 to pursue the investment in, development, acquisition, and management of residential and commercial income producing properties.
            </Typography>
            <Typography size={18} variant="subtitle2" color="primary" classes={{ root: classes.description2 }}>
              An integral part of our strategy is to pursue development opportunities and acquisitions through partnership arrangements with our Strategic Partners and other groups to accelerate our presence.
            </Typography>
            <div className={classes.investmentButton} />
            <div className={classes.description3}>
              <Typography size={28} variant="h2" color="primary">Mission</Typography>
              <Typography classes={{ root: classes.description31 }}>
                Create a vertically integrated value chain to generate stable and growing cash flow and enhance the value of the Company’s portfolio of assets and maximize long-term share price value through: identifying and accessing low cost capital, active asset management, diversification of projects, property development and management, and cost-control through building materials procurement cost savings.
              </Typography>
            </div>
            <div className={classes.growthImage} />
          </div>
          <div className={classes.establishmentImage} />
        </div>
      </BorderBox>
    </TabPanel>
  )
}

AboutUs.propTypes = {}
AboutUs.defaultProps = {}

export default AboutUs
