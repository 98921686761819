import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Logo from './Logo'
import Company from './Company'
import Investors from './Investors'
import Subscribe from './Subscribe'
import Copyright from './Copyright'
import AddressMobile from './AddressMobile'

const useStyles = makeStyles({
  container: {
    width: '100%',
    backgroundColor: 'white',
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 416,
    maxWidth: 1004,
    backgroundColor: '#fff',
    padding: '80px 0 20px',
    '@media (max-width: 768px)': {
      padding: '60px 48px 10px 48px',
      width: '100%',
    },
    '@media (max-width: 425px)': {
      padding: '40px 30px 10px 30px',
      width: '100%',
    },
    zIndex: 9999,
    margin: 'auto',
  },
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 40,
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: 1,
    '@media (max-width: 424px)': {
      flexDirection: 'column',
    },
  },
  contentGroup: {
    display: 'flex',
    marginLeft: 50,
    justifyContent: 'space-between',
    flex: 1,
    flexWrap: 'wrap',
    '@media (max-width: 768px)': {
      marginLeft: 30,
    },
    '@media (max-width: 424px)': {
      marginLeft: 0,
      marginTop: 32,
    },
  },
  contentItem: {
    '@media (max-width: 768px)': {
      width: '50%',
    },
    '@media (max-width: 500px)': {
      width: '100%',
    },
  },
})

const Footer = () => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div className={classes.footer}>
        <div className={classes.root}>
          <div className={classes.content}>
            <Logo />
            <div className={classes.contentGroup}>
              <div className={classes.contentItem}>
                <Company />
              </div>
              <div className={classes.contentItem}>
                <Investors />
              </div>
              <div className={classes.contentItem}>
                <AddressMobile />
              </div>
              <div className={classes.contentItem}>
                <Subscribe />
              </div>
            </div>
          </div>
        </div>
        <Copyright />
      </div>
    </div>
  )
}

export default Footer
