import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import Typography from 'components/Typography'
import { colors } from 'theme'

const useStyles = makeStyles({
  root: {
    padding: '0 30px',
  },
  bio: {
    paddingTop: 16,
    lineHeight: 2.5,
    fontWeight: 300,
    position: 'relative',
  },
  panelRoot: {
    background: 'transparent',
    boxShadow: 'none',
    borderBottom: '1px solid #57585a',
  },
  panelDetail: {
    padding: '15px 0px',
    marginBottom: 80,
  },
  panelSummary: {
    height: 80,
    padding: 0,
  },
  name: {
    fontSize: 22,
    fontWeight: 500,
    position: 'relative',
    display: 'inline',
    '&:after': {
      content: '""',
      backgroundColor: colors.primary,
      position: 'absolute',
      width: '100%',
      height: 1,
      bottom: -12,
      left: 0,
    },
  },
})

const MobileAccordion = (props) => {
  const classes = useStyles()
  const { summary, details } = props

  return (
    <div className={classes.root}>
      <Accordion
        classes={{ root: classes.panelRoot }}
        square
      >
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon />}
          classes={{
            root: classes.panelSummary,
          }}
        >
          <Typography variant="body2" size={18}>
            {summary}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          classes={{ root: classes.panelDetail }}
        >
          {details}
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

MobileAccordion.propTypes = {}
MobileAccordion.defaultProps = {}

export default MobileAccordion
