/**
 * Creates a maxwidth box around elements
 */
import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  root: {
    maxWidth: 1004,
    margin: '0 auto',
  },
})

const Container = (props) => {
  const { children } = props
  const classes = useStyles()

  return (
    <div className={classes.root}>
      {children}
    </div>
  )
}

Container.propTypes = {}
Container.defaultProps = {}

export default Container
