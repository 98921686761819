import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import MemberItem from './MemberItem'

const useStyles = makeStyles({
  root: {
    paddingBottom: 80,
  },
})

const MemberList = (props) => {
  const { members, isDirector, isManagement } = props
  const classes = useStyles()

  return (
    <div className={classes.root}>
      {
        members.map(x => (
          <MemberItem key={x.name} member={x} isDirector={isDirector} isManagement={isManagement} />
        ))
      }
    </div>
  )
}

MemberList.propTypes = {}
MemberList.defaultProps = {}

export default MemberList
