import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import Typography from 'components/Typography'
import Carousel from 'components/Carousel'
import homeHero from 'assets/images/homeHero.jpeg'
import slideshow2 from 'assets/images/slideshow2.jpg'
import slideshow3 from 'assets/images/Slideshow3.jpg'
import Logo from 'assets/images/KadestoneIcon.png'

const useSlideStyles = makeStyles(theme => ({
  bg: ({ bgSrc }) => ({
    backgroundImage: `url(${bgSrc})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100%',
    paddingBottom: 50,
    maxHeight: 550,
    height: '100vh',
    [theme.breakpoints.down('xs')]: {
      maxHeight: 400,
      backgroundImage: 'unset',
      backgroundColor: '#fff',
      padding: 0,
    },
  }),
  card: ({ cardPosition }) => ({
    padding: '64px 40px 50px',
    backgroundColor: '#fff',
    maxWidth: 440,
    minHeight: 450,
    marginLeft: cardPosition === 'right' ? 'auto' : 'unset',
    [theme.breakpoints.down('xs')]: {
      padding: '32px 32px 30px',
      minHeight: 'unset',
    },
  }),
  title: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 36,
    },
  },
  divider: {
    backgroundColor: theme.palette.primary.main,
    height: 2,
    margin: '40px 0px 16px',
  },
  logoGrid: {
    marginTop: 32,
  },
  cardContainer: {
    maxWidth: 1200,
    margin: '0px auto',
  },
}))

const Slide = (props) => {
  const { bgSrc, cardPosition, title, description } = props
  const classes = useSlideStyles({ bgSrc, cardPosition })

  return (
    <div className={classes.bg}>
      <div className={classes.cardContainer}>
        <div className={classes.card}>
          <Grid container direction="col" justify="space-between">
            <Grid xs={12} item>
              <Typography className={classes.title} variant="h1">{title}</Typography>
            </Grid>
            <Grid xs={3} item>
              <Divider className={classes.divider} />
            </Grid>
            <Grid xs={12} item>
              <Typography color="primary">{description}</Typography>
            </Grid>
            <Grid xs={12} item className={classes.logoGrid}>
              <img src={Logo} width={45} alt="Kadestone" />
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles({
  list: {
    padding: '0px 0px 0px 18px',
    margin: 0,
  },
})

const Slideshow = (props) => {
  const classes = useStyles()

  return (
    <div>
      <Carousel>
        <Slide
          bgSrc={homeHero}
          title="The Evolution of Real Estate Development"
          description="Building and investing stronger by creating strategic partnerships and engaging in multiple area's of real estate value-chain."
        />
        <Slide
          bgSrc={slideshow2}
          title="The 5 pillars of Kadestone"
          cardPosition="right"
          description={(
            <ul className={classes.list}>
              <li>Development and Construction</li>
              <li>Construction Financing</li>
              <li>Building Materials Supply</li>
              <li>Asset Ownership</li>
              <li>Property Management</li>
            </ul>
          )}
        />
        <Slide
          bgSrc={slideshow3}
          title={(<span>Kadestone<br />Strategy</span>)}
          description="To pursue investments in development, acquisition, and management of residental and commercial income producing properties."
        />
      </Carousel>
    </div>
  )
}

Slideshow.propTypes = {}
Slideshow.defaultProps = {}

export default Slideshow
