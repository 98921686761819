import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles } from "@material-ui/core/styles";
import Call from "@material-ui/icons/Call";
import EMail from "@material-ui/icons/Email";
import LocationOn from "@material-ui/icons/LocationOn";
import BuildValueImage from "assets/images/buildValue.jpg";

import Typography from "components/Typography";

const useStyles = makeStyles((theme) => ({
  root: {},
  container: {
    display: "flex",
  },
  contact: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#fff",
    flex: 1,
    padding: "126px 96px",
    [theme.breakpoints.down("xs")]: {
      padding: "64px 45px",
    },
  },
  name: {
    fontSize: 52,
    color: "#c48a43",
    [theme.breakpoints.down("xs")]: {
      fontFamily: "Aileron",
      fontSize: 30,
    },
  },
  position: {
    fontSize: 24,
    color: "#413f3f",
    [theme.breakpoints.down("xs")]: {
      fontFamily: "Aileron",
      fontSize: 16,
    },
  },
  separator: {
    width: 150,
    borderBottom: "1px solid #c48a43",
    marginTop: 32,
    marginBottom: 32,
    [theme.breakpoints.down("xs")]: {
      width: 90,
      marginTop: 16,
      marginBottom: 16,
    },
  },
  contactContainer: {
    display: "flex",
    marginBottom: 16,
  },
  title: {
    fontFamily: "Aileron",
    fontSize: 18,
    color: "#c6c5c5",
    width: 130,
    minWidth: 130,
    lineHeight: 1.9,
    [theme.breakpoints.down("xs")]: {
      width: 30,
      minWidth: 30,
      fontSize: 14,
      lineHeight: 1.5,
    },
  },
  value: {
    fontFamily: "Aileron",
    fontSize: 18,
    fontWeight: 600,
    color: "#413f3f",
    flex: 1,
    lineHeight: 1.9,
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
      lineHeight: 1.5,
    },
    textDecoration: "none",
  },
  background: {
    width: 320,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundImage: `url(${BuildValueImage})`,
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  icon: {
    color: "#c48a43",
    marginRight: 15,
  },
}));

const InvestorRelationsContact = (props) => {
  const classes = useStyles();
  const matchesXs = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.contact}>
          <Typography variant="subtitle1" className={classes.name}>
            David Negus
          </Typography>
          <Typography variant="subtitle1" className={classes.position}>
            Chief Financial Officer
          </Typography>
          <div className={classes.separator} />
          <div className={classes.contactContainer}>
            {matchesXs ? (
              <EMail className={classes.icon} />
            ) : (
              <Typography className={classes.title}>EMAIL</Typography>
            )}
            <Typography
              component="a"
              href="mailto:dnegus@kadestone.com"
              target="_blank"
              rel="noopenner noreferrer"
              className={classes.value}
            >
              dnegus@kadestone.com
            </Typography>
          </div>
          <div className={classes.contactContainer}>
            {matchesXs ? (
              <LocationOn className={classes.icon} />
            ) : (
              <Typography className={classes.title}>ADDRESS</Typography>
            )}
            <div>
              <Typography className={classes.value}>
                Suite 3500, The Stack
              </Typography>
              <Typography className={classes.value}>
                1133 Melville Street
              </Typography>
              <Typography className={classes.value}>Vancouver, BC</Typography>
              <Typography className={classes.value}>V6E 4E5</Typography>
            </div>
          </div>
        </div>
        <div className={classes.background} />
      </div>
    </div>
  );
};

InvestorRelationsContact.propTypes = {};
InvestorRelationsContact.defaultProps = {};

export default InvestorRelationsContact;
