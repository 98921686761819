const punctuation = /[!"#$%&'()*+,./:;<=>?@[\]^_`{|}~]/g

const createPostURL = (id, title) => `/newsroom/${id}/${title.replace(punctuation, '').replace(/\s/gi, '_')}`

export const getPostLink = (id, title, permalink) => {
  if (permalink) return `/newsroom/${permalink}`

  return createPostURL(id, title)
}

export default createPostURL
