import React from 'react'
import Tabs from 'components/Tabs'
import PageContentBox from 'components/PageContentBox'
import Newsroom from 'components/Newsroom'

import AboutUsTab from './AboutUs'
import OurMissionTab from './OurMission'
import Slideshow from './Slideshow'

const Landing = () => {
  const tabData = [
    { tabLabel: 'About Us', hash: 'about-us', tabBody: <PageContentBox><AboutUsTab /></PageContentBox> },
    { tabLabel: 'Objectives', hash: 'objectives', tabBody: <PageContentBox><OurMissionTab /></PageContentBox> },
  ]

  return (
    <div>
      <Slideshow />
      <Tabs data={tabData} />
      <Newsroom />
    </div>
  )
}

Landing.propTypes = {}
Landing.defaultProps = {}

export default Landing
