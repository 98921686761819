import { createMuiTheme } from '@material-ui/core/styles'
import { colors } from './theme'

export default createMuiTheme({
  typography: {
    fontFamily: [
      'Aileron',
      'Helvetica Neue',
      'Helvetica',
      'Arial',
      'sans-serif',
    ].join(','),
    button: {
      textTransform: 'none',
    },
    h1: {
      fontFamily: 'Century SchoolBook',
      fontWeight: 600,
      fontSize: 48,
    },
    h2: {
      fontFamily: 'Century SchoolBook',
      fontWeight: 400,
    },
    h4: {
      fontFamily: 'Century SchoolBook',
      fontWeight: 400,
    },
    h5: {
      fontFamily: 'Aileron',
      fontWeight: 600,
    },
    subtitle1: {
      fontFamily: 'Century SchoolBook',
      fontWeight: 400,
      lineHeight: 1.5,
    },
    subtitle2: {
      fontFamily: 'Century SchoolBook',
      fontWeight: 400,
      fontStyle: 'italic',
    },
    body2: {
      fontWeight: 300,
    },
  },
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
    text: {
      primary: colors.textPrimary,
      secondary: colors.textSecondary,
    },
  },
  shape: {
    borderRadius: 0,
  },
})
