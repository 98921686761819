/**
 * Used for mobile
 */
import React from 'react'
import PropTypes from 'prop-types'
import SwipeableViews from 'react-swipeable-views'
import { makeStyles } from '@material-ui/core/styles'
import NewsCard from './NewsCard'

const useStyles = makeStyles({
  view: {
    height: 250,
    margin: '24px 0',
  },
})

const SwipeableNewsroom = (props) => {
  const { posts } = props
  const classes = useStyles()

  return (
    <SwipeableViews autoPlay>
      {
        posts.map(({ id, title, urlPermalink, articlePreview, heroImage }) => (
          <div key={id} className={classes.view}>
            <NewsCard id={id} background={heroImage} title={title} urlPermalink={urlPermalink} subtitle={articlePreview} />
          </div>
        ))
      }
    </SwipeableViews>
  )
}

SwipeableNewsroom.propTypes = {}
SwipeableNewsroom.defaultProps = {}

export default SwipeableNewsroom
