import React from 'react'
import { useHistory } from 'react-router-dom'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  pointer: {
    cursor: 'pointer',
  },
})

const Card = (props) => {
  const { to, className, tabIndex } = props
  const history = useHistory()
  const classes = useStyles()

  const onClick = () => {
    // https://stackoverflow.com/a/36215301/4665490
    // don't trigger when text is highlighted
    let highlightedText = ''

    if (window.getSelection) {
      highlightedText = window.getSelection().toString()
    } else if (document.selection && document.selection.type !== 'Control') {
      highlightedText = document.selection.createRange().text
    }

    if (highlightedText !== '') return

    if (to) history.push(to)

    if (props.onClick) props.onClick()
  }

  const onKeyDown = (e) => {
    if (e.key === 'Enter') onClick()
  }

  return (
    <div
      onClick={onClick}
      onKeyDown={onKeyDown}
      tabIndex={tabIndex}
      role="button"
      className={clsx(
        className,
        { [classes.pointer]: to },
      )}
    >
      {props.children}
    </div>
  )
}


Card.propTypes = {}
Card.defaultProps = {}

export default Card
