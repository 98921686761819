import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import RecentBlogPosts from '../components/RecentBlogPosts'
import BlogSearchResults from '../components/BlogSearchResults'
import BlogListHeader from '../components/BlogListHeader'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '64px 76px',
    maxWidth: 1200,
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      padding: '64px 32px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '28px 20px',
    },
  },
  bg: {
    backgroundColor: '#f9f9f9',
  },
}))

const Home = (props) => {
  const {
    posts,
    tags,
    query,
    filterTag,
    onSearchChange,
    onTagChange,
  } = props
  const classes = useStyles()

  return (
    <div className={classes.bg}>
      <div className={classes.root}>
        <BlogListHeader
          searchQuery={query}
          onSearchChange={onSearchChange}
          tags={tags}
          filterTag={filterTag}
          onTagChange={onTagChange}
        />
        {
          (query.length >= 2) || filterTag
            ? <BlogSearchResults posts={posts} searchQuery={query} filterTag={filterTag} />
            : <RecentBlogPosts posts={posts} />
        }
      </div>
    </div>
  )
}

Home.propTypes = {}
Home.defaultProps = {}

export default Home
