import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import TextButton from '@material-ui/core/Button'
import ArrowBack from '@material-ui/icons/ArrowBackOutlined'
import ArrowNext from '@material-ui/icons/ArrowForwardOutlined'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { getPostLink } from 'utils/createPostURL'

import Typography from 'components/Typography'

const useStyles = makeStyles(theme => ({
  image: {
    maxHeight: 210,
    height: '100%',
  },
  articleButton: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    [theme.breakpoints.up('sm')]: {
      alignItems: 'flex-start',
    }
  },
  titleText: {
    fontFamily: 'Century SchoolBook',
    fontSize: 20,
  },
}))

const BlogPreviousNext = (props) => {
  const { previousPost, nextPost } = props
  const classes = useStyles()
  const matchesTablet = useMediaQuery(theme => theme.breakpoints.down('sm'))

  const previousURL = previousPost ? getPostLink(previousPost.id, previousPost.title, previousPost.urlPermalink) : ''
  const nextURL = nextPost ? getPostLink(nextPost.id, nextPost.title, nextPost.urlPermalink) : ''

  const clampHack = text => {
    if (text.length > 70) return text.substring(0, 70).trim().concat('...')
    return text
  }

  return (
    <Grid container spacing={5}>
      <Grid item xs={6} container>
        {
          previousPost
            ? (
              <TextButton fullWidth className={classes.articleButton} startIcon={<ArrowBack color="primary" />}>
                <Link to={previousURL}>
                  <Typography className={classes.titleText}>{matchesTablet ? 'Previous' : clampHack(previousPost.title)}</Typography>
                </Link>
              </TextButton>
            )
            : null
        }
      </Grid>
      <Grid item xs={6} container justify="flex-end">
        {
          nextPost
            ? (
              <TextButton fullWidth className={classes.articleButton} endIcon={<ArrowNext color="primary" />}>
                <Link to={nextURL}>
                  <Typography className={classes.titleText}>{matchesTablet ? 'Next' : clampHack(nextPost.title)}</Typography>
                </Link>
              </TextButton>
            )
            : null
        }
      </Grid>
    </Grid>
  )
}

BlogPreviousNext.propTypes = {}
BlogPreviousNext.defaultProps = {}

export default BlogPreviousNext
