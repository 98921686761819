import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import format from 'date-fns/format'
import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Card from 'components/Card'
import CardHover from 'components/Card/CardHover'
import LazyImage from 'components/LazyImage'
import Typography from 'components/Typography'
import SocialMediaBar from 'components/SocialMediaBar'
import { getPostLink } from 'utils/createPostURL'
import BlogTitle from './BlogTitle'
import BlogDescription from './BlogDescription'
import BlogTagList from './BlogTagList'

const useStyles = makeStyles((theme) => ({
  card: {
    padding: 20,
    marginBottom: 96,
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      marginBottom: 40,
    },
  },
  content: {
    marginLeft: 96,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  postDate: {
    opacity: 0.6,
    marginRight: 20,
  },
  image: {
    maxHeight: 400,
    [theme.breakpoints.down('sm')]: {
      maxHeight: 300,
    },
  },
}))

const BlogListItem = (props) => {
  const { post, tabIndex } = props
  const classes = useStyles()
  const matchesTablet = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const matchesDesktop = useMediaQuery('(min-width: 1160px)')

  const url = getPostLink(post.id, post.title, post.urlPermalink)

  const CreatedAt = (
    <Typography
      variant={matchesTablet ? 'h5' : 'subtitle1'}
      size={matchesTablet ? 12 : 36}
      color={matchesTablet ? 'textSecondary' : 'primary'}
    >
      {format(new Date(post.displayDate), matchesTablet ? 'MMM dd, yyyy' : 'MM/dd')}
    </Typography>
  )

  return (
    <CardHover disabled={matchesTablet}>
      <Card
        className={classes.card}
        to={url}
        tabIndex={tabIndex}
      >
        {
          matchesTablet
            ? null
            : CreatedAt
        }
        <div className={classes.content}>
          <BlogTagList tags={post.tags} />
          <BlogTitle color={matchesTablet ? 'textPrimary' : 'primary'} fontSize={matchesTablet ? 'small' : 'normal'} title={post.title} />
          {matchesDesktop ? <Typography><span className={classes.postDate}>Author</span> <b>{post.author}</b></Typography> : null}
          {!matchesTablet ? <BlogDescription description={post.articlePreview} /> : null}
          <Grid container direction="column">
            <Grid item xs={12}>
              <LazyImage src={post.heroImage.file.url} className={classes.image} alt={post.title} />
            </Grid>
            <Grid item xs={12}>
              <Grid container justify="space-between" alignItems="center">
                {
                  matchesTablet
                    ? (
                      <Grid item>
                        {CreatedAt}
                      </Grid>
                    )
                    : null
                }
                <Grid item>
                  <SocialMediaBar
                    title={post.title}
                    url={url}
                    hashtags={post.tags}
                    orientation="horizontal"
                    theme="normal"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        {matchesTablet && <Divider />}
      </Card>
    </CardHover>
  )
}

BlogListItem.propTypes = {}
BlogListItem.defaultProps = {}

export default BlogListItem
