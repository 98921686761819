/**
 * Creates a stylized border on one side of custom length.
 */
import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'theme'

const useStyles = makeStyles({
  root: {
    position: 'relative',
  },
  border: ({ position, length }) => {
    const mapPositionToStyles = {
      left: { left: 0 },
      right: { right: 0 },
    }

    return {
      zIndex: 50,
      position: 'absolute',
      width: 8,
      top: 0,
      height: length,
      backgroundColor: colors.primary,
      ...mapPositionToStyles[position] || {},
    }
  },
})

const Container = (props) => {
  const { position, length, children } = props
  const classes = useStyles({
    position,
    length,
  })

  return (
    <div className={classes.root}>
      {position && <div className={classes.border} />}
      {children}
    </div>
  )
}

Container.propTypes = {
  position: PropTypes.oneOf([
    'left',
    'right',
  ]),
}
Container.defaultProps = {
  position: null,
}

export default Container
