import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import BlogSearchBar from './BlogSearchBar'
import BlogFilter from './BlogFilter'

const useStyles = makeStyles((theme) => ({
  header: {
    margin: '0 0px 80px',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  filterInput: {
    maxWidth: 260,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 140,
    },
  },
}))

const BlogListHeader = (props) => {
  const {
    searchQuery,
    onSearchChange,
    tags,
    filterTag,
    onTagChange,
  } = props
  const classes = useStyles()

  const mappedTagsToOptions = tags.map(x => ({ label: x, value: x }))

  return (
    <div className={classes.header}>
      <div className={classes.filterInput}>
        <BlogSearchBar
          value={searchQuery}
          onChange={onSearchChange}
        />
      </div>
      <div className={classes.filterInput}>
        <BlogFilter
          label="Filter by tags"
          options={mappedTagsToOptions}
          value={filterTag}
          onChange={onTagChange}
        />
      </div>
    </div>
  )
}

BlogListHeader.propTypes = {}
BlogListHeader.defaultProps = {}

export default BlogListHeader
