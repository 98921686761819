import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Grow from '@material-ui/core/Grow'
import { makeStyles } from '@material-ui/core/styles'
import Typography from 'components/Typography'
import TextButton from 'components/TextButton'
import { drawerOptions } from '../MenuData'

const useStyles = makeStyles({
  root: () => ({
    position: 'fixed',
    width: 209,
    backgroundColor: '#fff',
    zIndex: 300,
    top: 110,
    right: 48,
    overflowY: 'auto',
    paddingLeft: 15,
    paddingRight: 15,
    boxShadow: '0 10px 30px 0 rgba(0, 0, 0, 0.11)',
    '@media (max-width: 425px)': {
      top: 60,
      right: 10,
    },
  }),
  menuItemLink: {
    '&:last-of-type > button': {
      borderBottom: '1px solid #fff',
    },
    textDecoration: 'none',
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: 16,
    paddingBottom: 16,
    borderTop: '1px solid #fff',
  },
  menuItemIcon: {
    width: 24,
    height: 24,
    marginRight: 16,
  },
  button: {
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 10,
    paddingRight: 10,
  },
  drawerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  separator: {
    width: '100%',
    borderBottom: '1px solid #cccccd',
  },
  title: {
    color: '#707070',
    '&:hover': {
      color: '#c48a43',
    },
  },
})

const MenuItem = ({ icon, title, link, onClick }) => {
  const classes = useStyles()

  return (
    <Link to={link} className={classes.menuItemLink}>
      <TextButton onClick={onClick} align="left" fullWidth className={classes.menuItem}>
        <Typography className={classes.title}>{title}</Typography>
      </TextButton>
    </Link>
  )
}

const MobileDrawer = ({ open, onClose }) => {
  const themeColor = useSelector(({ app }) => app.themeColor)
  const classes = useStyles({ themeColor })

  return (
    <Grow in={open} timeout={300} style={{ transformOrigin: 'top right' }}>
      <div className={classes.root}>
        {
          drawerOptions.map(({ id, label, icon, link }, index) => (
            <div className={classes.drawerContainer}>
              <MenuItem key={id} title={label} icon={icon} link={link} onClick={onClose} />
              {index < drawerOptions.length - 1 && <div className={classes.separator} />}
            </div>
          ))
        }
      </div>
    </Grow>
  )
}

MobileDrawer.propTypes = {}
MobileDrawer.defaultProps = {}

export default MobileDrawer
