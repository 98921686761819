import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import MobileMemberItem from './MobileMemberItem'

const useStyles = makeStyles({
  root: {
    paddingBottom: 80,
  },
})

const MobileMemberList = (props) => {
  const { members, isDirector, isManagement } = props
  const classes = useStyles()

  return (
    <div className={classes.root}>
      {
        members.map(x => (
          <MobileMemberItem key={x.name} member={x} isDirector={isDirector} isManagement={isManagement} />
        ))
      }
    </div>
  )
}

MobileMemberList.propTypes = {}
MobileMemberList.defaultProps = {}

export default MobileMemberList
