/**
 * Configured Carousel component with custom dots
 */
import React, { useState } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import Grid from '@material-ui/core/Grid'

import ReactCarousel from '@brainhubeu/react-carousel'
import '@brainhubeu/react-carousel/lib/style.css'

const useStyles = makeStyles(theme => ({
  dot: {
    width: 18,
    height: 18,
    borderRadius: 9,
    backgroundColor: theme.palette.primary.main,
  },
  active: {
    backgroundColor: theme.palette.primary.dark,
  },
  carousel: {
    // marginBottom: 32,
  },
}))

const Carousel = (props) => {
  const { children } = props
  const classes = useStyles()
  const [value, setValue] = useState(0)

  const onChange = (val) => {
    setValue(val)
  }

  const getNumDots = () => {
    if (!children) return 0 // no children

    if (Array.isArray(children)) return children.length // >1 child

    return 1 // children is a single element
  }

  const numDots = getNumDots()

  return (
    <div>
      <div className={classes.carousel}>
        <ReactCarousel
          autoPlay={5000}
          infinite
          value={value}
          onChange={onChange}
        >
          {children}
        </ReactCarousel>
      </div>
      {/* <Grid container spacing={10} justify="center">
        {
          new Array(numDots).fill(0).map((x, i) => (
            // eslint-disable-next-line
            <Grid key={i} item>
              <IconButton onClick={() => { onChange(i) }} size="small">
                <div
                  className={clsx(
                    classes.dot,
                    { [classes.active]: i === value },
                  )}
                />
              </IconButton>
            </Grid>
          ))
        }
      </Grid> */}
    </div>
  )
}

Carousel.propTypes = {}
Carousel.defaultProps = {}

export default Carousel
