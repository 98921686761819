import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import BorderBox from 'components/BorderBox'
import Typography from 'components/Typography'
import MissionTopImage from 'assets/images/missionTop.jpeg'
import MissionBottomImage from 'assets/images/growthImage.jpeg'
import TabPanel from './TabPanel'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  rootBottom: {
    marginTop: 130,
    [theme.breakpoints.down('xs')]: {
      marginTop: 48,
    },
  },
  boxBottom: {
    height: 725,
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      height: 600,
    },
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
    },
  },
  boxContent: {
    maxWidth: 664,
    marginLeft: 'auto',
    padding: '70px 84px',
    backgroundColor: '#fff',
    position: 'relative',
    zIndex: 10,
    [theme.breakpoints.down('sm')]: {
      padding: '32px 40px',
      maxWidth: 550,
    },
  },
  boxContentLeft: {
    marginLeft: 'unset',
    marginRight: 'auto',
    padding: '100px 85px',
    [theme.breakpoints.down('sm')]: {
      padding: '50px 30px',
    },
  },
  blurbWidth: {
    maxWidth: 325,
    width: '100%',
    marginBottom: 48,
    '&:nth-child(even)': {
      marginBottom: 0,
      marginLeft: 'auto',
    },
    [theme.breakpoints.down('xs')]: {
      '&:nth-child(even)': {
        marginLeft: 'unset',
      },
    },
  },
  textWidth: {
    maxWidth: 275,
    width: '100%',
    marginLeft: 'auto',
    lineHeight: 2,
  },
  missionImage1: {
    width: 400,
    height: 492,
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${MissionTopImage})`,
    [theme.breakpoints.down('sm')]: {
      height: 400,
    },
    [theme.breakpoints.down('xs')]: {
      height: 250,
      width: '100%',
      position: 'relative',
    },
  },
  missionImage2: {
    width: 400,
    height: 725,
    position: 'absolute',
    top: 0,
    right: 0,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${MissionBottomImage})`,
    [theme.breakpoints.down('sm')]: {
      height: 600,
    },
    [theme.breakpoints.down('xs')]: {
      height: 300,
      width: '100%',
      position: 'relative',
    },
  },
}))

const OurMission = (props) => {
  const classes = useStyles()

  return (
    <TabPanel>
      <div className={classes.root}>
        <BorderBox position="right" length={240}>
          <div className={classes.boxContent}>
            <div className={classes.blurbWidth}>
              <Typography size={20} variant="h2" color="primary">01</Typography>
              <Typography variant="body2" classes={{ root: classes.textWidth }}>Generate stable and predictable cash flow with an opportunity for capital appreciation and growing returns.</Typography>
            </div>
            <div className={classes.blurbWidth}>
              <Typography size={20} variant="h2" color="primary">02</Typography>
              <Typography variant="body2" classes={{ root: classes.textWidth }}>Where appropriate seek co-investment and co-development opportunities to establish its market presence and diversify project risk.</Typography>
            </div>
          </div>
          <div className={classes.missionImage1} />
        </BorderBox>
      </div>
      <div className={clsx(classes.root, classes.rootBottom)}>
        <div className={classes.boxBottom}>
          <BorderBox position="left" length={240}>
            <div className={clsx(classes.boxContent, classes.boxContentLeft)}>
              <div className={classes.blurbWidth}>
                <Typography size={20} variant="h2" color="primary">03</Typography>
                <Typography variant="body2" classes={{ root: classes.textWidth }}>Build value in our businesses through the company’s strategic relationships by partnering with leading developers and building and construction materials suppliers to drive down costs of development.</Typography>
              </div>
              <div className={classes.blurbWidth}>
                <Typography size={20} variant="h2" color="primary">04</Typography>
                <Typography variant="body2" classes={{ root: classes.textWidth }}>Generate stable and growing cash flow and enhance the value of the Company’s portfolio of assets and maximize long-term share price value through: active asset management, diversification of Projects, property management, identifying and accessing low cost capital, and cost-control through procurement cost savings.</Typography>
              </div>
            </div>
          </BorderBox>
        </div>
        <div className={classes.missionImage2} />
      </div>
    </TabPanel>
  )
}

OurMission.propTypes = {}
OurMission.defaultProps = {}

export default OurMission
