import React, { useEffect, useState, useMemo } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import format from 'date-fns/format'
import { makeStyles } from '@material-ui/core/styles'
import Container from 'components/Container'
import Typography from 'components/Typography'
import LazyImage from 'components/LazyImage'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import ArrowBack from '@material-ui/icons/ArrowBack'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { getPostLink } from 'utils/createPostURL'
import SocialMediaBar from 'components/SocialMediaBar'
import RelatedArticles from '../components/RelatedArticles'
import BlogTagList from '../components/BlogTagList'
import BlogPreviousNext from '../components/BlogPreviousNext'
import BlogMorePosts from '../components/BlogMorePosts'
import { fetchBlogPost } from '../blog.module'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
  },
  placeholder: {
    minHeight: '100vh',
  },
  bodyRoot: {
    backgroundColor: '#fff',
  },
  backButton: {
  },
  bodyContent: {
    marginTop: 72,
    [theme.breakpoints.down('sm')]: {
      marginTop: 24,
    },
  },
  body: {
    width: '100%',
    padding: '40px 100px',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      padding: '40px 30px',
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 16,
  },
  title: {
    marginTop: 40,
    marginBottom: 64,
    [theme.breakpoints.down('sm')]: {
      marginTop: 36,
      marginBottom: 24,
    },
  },
  authorLabel: {
    opacity: 0.6,
    marginRight: 20,
  },
  tags: {
  },
  createdAt: {
  },
  footer: {
    marginTop: 64,
    marginBottom: 128,
    [theme.breakpoints.down('sm')]: {
      marginTop: 30,
      marginBottom: 30,
    },
  },
}))

const BlogPage = (props) => {
  const { match, posts } = props
  const [post, setPost] = useState(null)

  const { id: postId, urlPermalink } = match.params

  const classes = useStyles()
  const matchesTablet = useMediaQuery(theme => theme.breakpoints.down('sm'))

  useEffect(() => {
    if (postId || urlPermalink) {
      setPost(null)
      const getPost = async () => {
        const res = await fetchBlogPost(postId, urlPermalink)

        setPost({
          ...res.fields,
          id: res.sys.id,
          createdAt: res.sys.createdAt,
          heroImage: res.fields.heroImage.fields,
          displayDate: res.fields.publishedAt || res.sys.createdAt,
        })
      }

      getPost()
    }
  }, [postId, urlPermalink])

  if (!post) return <div className={classes.placeholder} />

  // find previous and next posts
  let previous = null
  let next = null

  posts.forEach((x, i) => {
    if (x.id === postId || x.urlPermalink === urlPermalink) {
      // sorted by newest to oldest
      previous = posts[i + 1]
      next = posts[i - 1]

      if (i === 0) {
        // first post so no next post
        next = null
      }

      if (i === posts.length) {
        // last post so no previous
        previous = null
      }
    }
  })

  return (
    <div className={classes.root}>
      <Container>
        <div className={classes.bodyRoot}>
          <div className={classes.body}>
            <div className={classes.header}>
              <div>
                <Grid container alignItems="center">
                  <Link to="/newsroom">
                    <IconButton>
                      <ArrowBack color="primary" className={classes.backButton} />
                    </IconButton>
                  </Link>
                  {matchesTablet ? null : <Typography variant="subtitle1" size={20}>Newsroom</Typography>}
                </Grid>
              </div>
              <div className={classes.tags}>
                <BlogTagList tags={post.tags} />
              </div>
            </div>
            <Divider />
            <div className={classes.title}>
              <Typography variant={matchesTablet ? 'h5' : 'subtitle1'} size={matchesTablet ? 30 : 52}>{post.title}</Typography>
            </div>
            <Grid container alignItems="center" justify="space-between">
              <Grid item xs={12} sm="auto">
                <Grid container spacing={6} alignItems="center" justify="space-between">
                  <Grid item>
                    <Typography><span className={classes.authorLabel}>Author</span><b>{post.author}</b></Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.createdAt} color="primary">{format(new Date(post.displayDate), 'MM/dd/yyyy')}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              {
                matchesTablet
                  ? null
                  : (
                    <Grid item>
                      <SocialMediaBar
                        title={post.title}
                        hashtags={post.tags}
                        url={getPostLink(post.id, post.title, post.urlPermalink)}
                        orientation="horizontal"
                        theme="normal"
                      />
                    </Grid>
                  )
              }
            </Grid>
            <div className={classes.bodyContent}>
              {post.body.map(x => x)}
            </div>
            <Grid container justify={matchesTablet ? 'flex-start' : 'flex-end'} alignItems="center">
              <Grid item>
                <SocialMediaBar
                  title={post.title}
                  hashtags={post.tags}
                  url={getPostLink(post.id, post.title, post.urlPermalink)}
                  orientation="horizontal"
                  theme="normal"
                />
              </Grid>
            </Grid>
          </div>
        </div>
        <Divider />
      </Container>
      <div className={classes.footer}>
        <Container>
          <BlogPreviousNext
            previousPost={previous}
            nextPost={next}
          />
        </Container>
      </div>
      <RelatedArticles posts={posts} />
    </div>
  )
}

BlogPage.propTypes = {}
BlogPage.defaultProps = {}

export default BlogPage
