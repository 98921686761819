import React from 'react'
import { Link } from 'react-router-dom'
import Typography from 'components/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { getPostLink } from 'utils/createPostURL'

const useCardStyles = makeStyles({
  root: {
    height: '100%',
    position: 'relative',
  },
  title: {
    background: 'linear-gradient(0deg, rgba(0,0,0,1) 10%, rgba(255,255,255,0) 100%)',
    zIndex: 10,
    padding: 20,
    height: '100%',
    display: 'flex',
    alignItems: 'flex-end',
  },
  image: ({ background }) => ({
    position: 'absolute',
    backgroundImage: `url(${background})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundColor: '#222',
    width: '100%',
    height: '100%',
    zIndex: 9,
  }),
})

const NewsCard = (props) => {
  const { id, title, urlPermalink, subtitle, background = null } = props
  const classes = useCardStyles({ background })

  const isTooLong = title.length > 60
  const shortenedTitle = isTooLong ? title.slice(0, 60) : title

  return (
    <Link to={getPostLink(id, title, urlPermalink)}>
      <Grid container justify="flex-end" direction="column" className={classes.root}>
        <div className={classes.image} />
        <Typography classes={{ root: classes.title }} color="secondary">{isTooLong ? shortenedTitle.concat('...') : shortenedTitle}</Typography>
        <Typography size={10} color="secondary">{subtitle}</Typography>
      </Grid>
    </Link>
  )
}

export default NewsCard
