import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Typography from 'components/Typography'
import { colors } from 'theme'

const useStyles = makeStyles(theme => ({
  item: {
    marginTop: 88,
    marginBottom: 88,
  },
  bio: {
    paddingTop: 48,
    paddingLeft: 85,
    lineHeight: 2.5,
    fontWeight: 300,
    position: 'relative',
  },
  leftBorder: {
    '&:after': {
      content: '""',
      backgroundColor: colors.primary,
      position: 'absolute',
      width: 1,
      height: 120,
      top: 60,
      left: 0,
    },
  },
  bottomBorder: {
    position: 'relative',
    '&:after': {
      content: '""',
      backgroundColor: colors.primary,
      position: 'absolute',
      width: 120,
      height: 1,
      bottom: -8,
      left: 0,
    },
  },
}))

const MemberItem = (props) => {
  const { member, isManagement, isDirector } = props
  const { name, position, bio } = member
  const classes = useStyles()

  return (
    <div className={classes.item}>
      <Typography variant="subtitle1" size={36} color="primary">{name}</Typography>
      {
        isManagement ? <Typography className={classes.bottomBorder} variant="subtitle1" size={20}>{position}</Typography> : null
      }
      <Typography
        className={clsx(
          classes.bio,
          { [classes.leftBorder]: isDirector },
        )}
      >
        {bio}
      </Typography>
    </div>
  )
}

MemberItem.propTypes = {}
MemberItem.defaultProps = {}

export default MemberItem
