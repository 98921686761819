/**
 * Displays the tab header and associated content.
 * It uses an array of objects with tabLabel and tabBody components.
 */
import React, { useEffect, useState } from 'react'
import { useLocation, useHistory, Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import jump from 'jump.js'
import SwipeableViews from 'react-swipeable-views'
import Container from 'components/PageContentBox'
import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

const useStyles = makeStyles({
  root: {
    backgroundColor: '#000',
  },
  indicator: {
    height: 8,
    top: 0,
    bottom: 'unset',
  },
})

const useTabStyles = makeStyles(theme => ({
  root: {
    minHeight: 120,
    flex: 1,
    maxWidth: 'unset',
    fontWeight: 600,
    [theme.breakpoints.down('xs')]: {
      minHeight: 48,
    },
  },
  textColorPrimary: {
    color: '#fff',
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  textColorSecondary: {
    color: '#aaabad',
  },
  selected: {
    opacity: 1,
  },
}))

const TabsWrapper = (props) => {
  const { data } = props
  const location = useLocation()
  const history = useHistory()
  const [value, setValue] = useState(0)
  const classes = useStyles()
  const tabClasses = useTabStyles()
  const matchesMobile = useMediaQuery(theme => theme.breakpoints.down('xs'))

  useEffect(() => {
    // look for the matching hash
    data.forEach((x, i) => {
      if (`#${x.hash}` === location.hash) {
        setValue(i)
        jump('#tabHead', {
          offset: -107,
        })
      }
    })
  }, [data.length, location.hash])

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleChangeIndex = (index) => {
    setValue(index)
    // have to programmatically update hash with this
    const { hash } = data[index]

    history.push(`#${hash}`)
  }

  const renderTabBody = () => data.map((x, i) => (
    value === i
      ? (
        <div key={`tabBody-${i}`}>
          {x.tabBody}
        </div>
      )
      : <span key={`tabBody-${i}`} />
  ))

  return (
    <div id="tabHead">
      <div className={classes.root}>
        <Container>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="secondary"
            classes={{
              // root: classes.root,
            }}
            TabIndicatorProps={{
              className: classes.indicator,
            }}
          >
            {data.map((x, i) => (
              <Tab
                component={Link}
                to={`#${x.hash}`}
                key={`tabLabel-${i}`}
                classes={tabClasses}
                label={x.tabLabel}
              />
            ))}
          </Tabs>
        </Container>
      </div>
      {
        matchesMobile
          ? (
            <SwipeableViews
              index={value}
              onChangeIndex={handleChangeIndex}
              enableMouseEvents
            >
              {renderTabBody()}
            </SwipeableViews>
          )
          : renderTabBody()
      }
    </div>
  )
}

TabsWrapper.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      tabLabel: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
      ]),
      tabBody: PropTypes.node,
    }),
  ),
}

TabsWrapper.defaultProps = {
  data: [],
}

export default TabsWrapper
