export const company = [
  { id: 'about-us', label: 'About Us', link: '/' },
  { id: 'management', label: 'Directors & Management', link: '/directors-and-management' },
  { id: 'newsroom', label: 'Newsroom', link: '/newsroom' },
]

export const investors = [
  { id: 'agm', label: 'AGM Documents', link: '/investors#agm-documents' },
  { id: 'documents', label: 'Governance Documents', link: '/investors#governance-documents' },
  { id: 'results', label: 'Financial Results', link: '/investors#financial-results' },
  { id: 'investors', label: 'Contact Us', link: '/investors#contact' },
]

export default {}
