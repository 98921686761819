exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var getUrl = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL___0___ = getUrl(require("../../public/assets/fonts/Aileron-Regular.otf"));
var ___CSS_LOADER_URL___1___ = getUrl(require("../../public/assets/fonts/Aileron-UltraLight.otf"));
var ___CSS_LOADER_URL___2___ = getUrl(require("../../public/assets/fonts/Aileron-Thin.otf"));
var ___CSS_LOADER_URL___3___ = getUrl(require("../../public/assets/fonts/Aileron-Light.otf"));
var ___CSS_LOADER_URL___4___ = getUrl(require("../../public/assets/fonts/Aileron-SemiBold.otf"));
var ___CSS_LOADER_URL___5___ = getUrl(require("../../public/assets/fonts/Aileron-Bold.otf"));
var ___CSS_LOADER_URL___6___ = getUrl(require("../../public/assets/fonts/Aileron-Black.otf"));
var ___CSS_LOADER_URL___7___ = getUrl(require("../../public/assets/fonts/CenturySchoolBook-Regular.ttf"));
var ___CSS_LOADER_URL___8___ = getUrl(require("../../public/assets/fonts/CenturySchoolBook-Bold.ttf"));
var ___CSS_LOADER_URL___9___ = getUrl(require("../../public/assets/fonts/CenturySchoolBook-BoldItalic.ttf"));
var ___CSS_LOADER_URL___10___ = getUrl(require("../../public/assets/fonts/CenturySchoolBook-Italic.ttf"));
// Module
exports.push([module.id, "@font-face{font-family:Aileron;src:url(" + ___CSS_LOADER_URL___0___ + ")}@font-face{font-family:Aileron;src:url(" + ___CSS_LOADER_URL___1___ + ");font-weight:100}@font-face{font-family:Aileron;src:url(" + ___CSS_LOADER_URL___2___ + ");font-weight:200}@font-face{font-family:Aileron;src:url(" + ___CSS_LOADER_URL___3___ + ");font-weight:300}@font-face{font-family:Aileron;src:url(" + ___CSS_LOADER_URL___4___ + ");font-weight:500}@font-face{font-family:Aileron;src:url(" + ___CSS_LOADER_URL___5___ + ");font-weight:600}@font-face{font-family:Aileron;src:url(" + ___CSS_LOADER_URL___6___ + ");font-weight:700}@font-face{font-family:Century SchoolBook;src:url(" + ___CSS_LOADER_URL___7___ + ");font-weight:400}@font-face{font-family:Century SchoolBook;src:url(" + ___CSS_LOADER_URL___8___ + ");font-weight:600}@font-face{font-family:Century SchoolBook;src:url(" + ___CSS_LOADER_URL___9___ + ");font-weight:600;font-style:italic}@font-face{font-family:Century SchoolBook;src:url(" + ___CSS_LOADER_URL___10___ + ");font-weight:400;font-style:italic}a{text-decoration:none;color:inherit}", ""]);
