import React from 'react'
import PropTypes from 'prop-types'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Tabs from 'components/Tabs'
import PageHero from 'components/PageHero'
import PageContentBox from 'components/PageContentBox'
import PageHeroImage from 'assets/images/Slideshow3.jpg'
import MemberList from './MemberList'
import MobileMemberList from './MobileMemberList'
import MembersData from './data'

const DirectorsAndManagement = (props) => {
  const matchesDesktop = useMediaQuery(theme => theme.breakpoints.up('sm'))

  const TabData = [
    {
      tabLabel: 'MANAGEMENT',
      hash: 'management',
      tabBody: (
        matchesDesktop
          ? <MemberList members={MembersData.ManagementData} isManagement />
          : <MobileMemberList members={MembersData.ManagementData} isManagement />
      ),
    },
    {
      tabLabel: 'BOARD OF DIRECTORS',
      hash: 'board-of-directors',
      tabBody: (
        matchesDesktop
          ? <MemberList members={MembersData.BoardData} isDirector />
          : <MobileMemberList members={MembersData.BoardData} isDirector />
      ),
    },
  ]

  return (
    <div>
      {matchesDesktop ? <PageHero image={PageHeroImage} title="Directors & Management" /> : null}
      <Tabs data={TabData.map(x => ({ ...x, tabBody: <PageContentBox>{x.tabBody}</PageContentBox>}))} />
    </div>
  )
}

DirectorsAndManagement.propTypes = {}
DirectorsAndManagement.defaultProps = {}

export default DirectorsAndManagement
