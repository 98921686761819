/**
 * Creates a maxwidth box around elements
 */
import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 1280,
    margin: '0 auto',
    // padding: '0 20px',
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
}))

const PageContentBox = (props) => {
  const { children } = props
  const classes = useStyles()

  return (
    <div className={classes.root}>
      {children}
    </div>
  )
}

PageContentBox.propTypes = {}
PageContentBox.defaultProps = {}

export default PageContentBox
