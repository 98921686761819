// display the tags in a breadcrumb style
import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const useStyles = makeStyles({
  tag: {
    backgroundColor: '#f4ece2',
    padding: 10,
    borderRadius: 3,
    fontWeight: 700,
    lineHeight: 1,
  },
})

const BlogTagList = (props) => {
  const { isLinked, tags } = props
  const classes = useStyles()
  const matchesTablet = useMediaQuery(theme => theme.breakpoints.down('sm'))

  const TagBox = tbProps => <Typography className={classes.tag} color="primary">{tbProps.tag}</Typography>

  return (
    <div>
      <Breadcrumbs separator={null}>
        {
          tags.map(x => (
            isLinked
              ? (
                <Link key={x} to="/newsroom">
                  <TagBox tag={x} />
                </Link>
              )
              : <TagBox key={x} tag={x} />
          ))
        }
      </Breadcrumbs>
    </div>
  )
}

BlogTagList.propTypes = {
  // if true, tag is a link otherwise just a text
  isLinked: PropTypes.bool,
}
BlogTagList.defaultProps = {
  isLinked: false,
}

export default BlogTagList

