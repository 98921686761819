/**
 * An in-page component to show news articles
 */
import React from 'react'
import PropTypes from 'prop-types'
import Typography from 'components/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Container from 'components/Container'
import NewsCard from 'components/Newsroom/NewsCard'
import SwipeableNewsroom from 'components/Newsroom/SwipeableNewsroom'

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: '#f9f9f9',
    width: '100%',
    padding: '60px 0',
    [theme.breakpoints.down('sm')]: {
      padding: '30px',
    },
  },
  postsContainer: {
    margin: '32px 0 24px',
  },
  gridItem: {
    '&:before': {
      content: '""',
      paddingTop: '100%',
      float: 'left',
    },
  },
}))

const Newsroom = (props) => {
  const { posts } = props
  const classes = useStyles()
  const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('xs'))
  const matchesSm = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const filteredPosts = matchesSm ? posts.slice(0, 3) : posts.slice(0, 4)

  const mappedPosts = filteredPosts.map(x => ({
    id: x.id,
    title: x.title,
    heroImage: x.heroImage.file.url,
    subtitle: x.articlePreview,
  }))

  return (
    <div className={classes.content}>
      <Container>
        <Typography variant="subtitle1" size={28} color="primary">Related Articles</Typography>
        {
          matchesXs
            ? <SwipeableNewsroom posts={mappedPosts} />
            : (
              <Grid container classes={{ container: classes.postsContainer }}>
                {
                  mappedPosts.map((x) => (
                    <Grid key={x.id} item sm={4} md={3} classes={{ item: classes.gridItem }}>
                      <NewsCard title={x.title} id={x.id} urlPermalink={x.urlPermalink} background={x.heroImage} />
                    </Grid>
                  ))
                }
              </Grid>
            )
        }
      </Container>
    </div>
  )
}

Newsroom.propTypes = {}
Newsroom.defaultProps = {}

export default Newsroom
