// displays a centered title and a full width hero image
import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Typography from 'components/Typography'

const useStyles = makeStyles({
  image: (props) => ({
    width: '100%',
    height: 400,
    padding: '0px 20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    backgroundImage: `url(${props.image})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: '#231f20',
      opacity: 0.45,
    },
  }),
  title: {
    zIndex: 1,
  },
})

const PageHero = (props) => {
  const { image, title } = props
  const classes = useStyles({ image })

  return (
    <div className={classes.image}>
      <Typography className={classes.title} color="secondary" variant="h2">
        {title}
      </Typography>
    </div>
  )
}

PageHero.propTypes = {}
PageHero.defaultProps = {}

export default PageHero
