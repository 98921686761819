import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Typography from 'components/Typography'

import SubscribeButton from 'components/SubscribeButton'
import SubscriptionField from 'components/SubscriptionField'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    '@media (max-width: 425px)': {
      marginBottom: 20,
    },
  },
  description2: {
    marginBottom: 24,
    fontSize: 16,
    '@media (max-width: 425px)': {
      marginBottom: 16,
      fontSize: 12,
    },
  },
})

const Logo = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Typography size={16} variant="body2" color="primary" classes={{ root: classes.description2 }}>
        Subscribe
      </Typography>
      <SubscriptionField />
    </div>
  )
}

export default Logo
