import React from 'react'
import PropTypes from 'prop-types'
import Typography from 'components/Typography'

import { makeStyles } from '@material-ui/core/styles'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'

const useStyles = makeStyles({
  text: {
    fontSize: 18,
    fontVariationSettings: '"wght" 300',
  },
})

const BlogFilter = (props) => {
  const { label, value, options, onChange } = props
  const classes = useStyles()

  const labelId = label.toLowerCase().replace(/ /g, '-')

  return (
    <FormControl fullWidth>
      <Select
        labelId={labelId}
        value={value}
        onChange={onChange}
        displayEmpty
        input={<Input className={classes.text} />}
      >
        <MenuItem value=""><Typography className={classes.text}>None</Typography></MenuItem>
        {
          options.map(x => (
            <MenuItem key={`${x.label}-${x.value}`} value={x.value}>
              <Typography className={classes.text}>{x.label}</Typography>
            </MenuItem>
          ))
        }
      </Select>
    </FormControl>
  )
}

BlogFilter.propTypes = {}
BlogFilter.defaultProps = {}

export default BlogFilter
