import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Typography from "components/Typography";
import { colors } from "theme";

const useStyles = makeStyles({
  root: {
    flexDirection: "column",
    display: "none",
    "@media (max-width: 768px)": {
      display: "flex",
    },
    "@media (max-width: 425px)": {
      marginBottom: 16,
    },
  },
  description: {
    marginTop: 16,
    color: "#707070",
    fontSize: 12,
  },
  description2: {
    color: "#707070",
    marginTop: 5,
    fontSize: 12,
  },
});

const Logo = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography size={12} variant="body2" color="primary">
        Address
      </Typography>
      <Typography size={12} classes={{ root: classes.description }}>
        Suite 3500
      </Typography>
      <Typography size={12} classes={{ root: classes.description2 }}>
        The Stack
      </Typography>
      <Typography size={12} classes={{ root: classes.description2 }}>
        1133 Melville Street,
      </Typography>
      <Typography size={12} classes={{ root: classes.description2 }}>
        Vancouver, BC
      </Typography>
      <Typography size={12} classes={{ root: classes.description2 }}>
        V6E 4E5
      </Typography>
    </div>
  );
};

export default Logo;
