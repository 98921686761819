/**
 * Because we do not have a set type system at the time of writing,
 * there is a lot of unnecessary overhead to adjust font-sizes
 *
 * This component wrap the MaterialUI Typography component
 * and add a prop to change font-sizes inline.
 */
import React from 'react'
import PropTypes from 'prop-types'
import MUITypography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  root: ({ size }) => ({ fontSize: size }),
})

const Typography = (props) => {
  const { size, ...rest } = props
  const componentClasses = useStyles({ size })

  return (
    <MUITypography
      className={componentClasses.root}
      {...rest} // eslint-disable-line
    />
  )
}

Typography.propTypes = {
  size: PropTypes.number,
}
Typography.defaultProps = {
  size: null,
}

export default Typography
