//
import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import ImageIcon from '@material-ui/icons/Image'
import AudiotrackIcon from '@material-ui/icons/Audiotrack'
import VideocamIcon from '@material-ui/icons/Videocam'
import DescriptionIcon from '@material-ui/icons/Description'
import GetAppIcon from '@material-ui/icons/GetApp'
import ArchiveIcon from '@material-ui/icons/Archive'
import CodeIcon from '@material-ui/icons/Code'
import PDFSVG from 'assets/icons/pdf.svg'
import DOCSVG from 'assets/icons/doc.svg'
import XLSXSVG from 'assets/icons/excel.svg'
import PPTSVG from 'assets/icons/powerpoint.svg'

const useImageStyles = makeStyles({
  root: ({ src }) => ({
    backgroundImage: `url(${src})`,
    width: 40,
    height: 40,
  }),
})

const ImageLoader = ({ src }) => {
  const classes = useImageStyles({ src })

  return <div className={classes.root} />
}

const mapTypeToIcon = {
  // Media
  image: <ImageIcon />,
  audio: <AudiotrackIcon />,
  video: <VideocamIcon />,
  // Documents
  'application/pdf': <ImageLoader src={PDFSVG} />,
  'application/msword': <ImageLoader src={DOCSVG} />,
  'application/vnd.ms-word': <ImageLoader src={DOCSVG} />,
  'application/vnd.oasis.opendocument.text': <ImageLoader src={DOCSVG} />,
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': <ImageLoader src={DOCSVG} />,
  'application/vnd.ms-excel': <ImageLoader src={XLSXSVG} />,
  'application/vnd.openxmlformats-officedocument.spreadsheetml': <ImageLoader src={XLSXSVG} />,
  'application/vnd.oasis.opendocument.spreadsheet': <ImageLoader src={XLSXSVG} />,
  'application/vnd.ms-powerpoint': <ImageLoader src={PPTSVG} />,
  'application/vnd.openxmlformats-officedocument.presentationml': <ImageLoader src={PPTSVG} />,
  'application/vnd.oasis.opendocument.presentation': <ImageLoader src={PPTSVG} />,
  'text/plain': <DescriptionIcon />,
  'text/html': <CodeIcon />,
  'application/json': <CodeIcon />,
  // Archives
  'application/gzip': <ArchiveIcon />,
  'application/zip': <ArchiveIcon />,
}


const useStyles = makeStyles({
  root: {
    margin: '16px 0px',
  },
})

const FileDownload = ({ type, src, title, size }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Grid container alignItems="center" spacing={2}>
        <Grid item>{mapTypeToIcon[type]}</Grid>
        <Grid item>
          <p>{title}</p>
          <p>{(size / (1024 ** 2)).toFixed(2)}mb</p>
        </Grid>
        <Grid item>
          <IconButton onClick={() => { window.open(src, '_blank')}}>
            <GetAppIcon />
          </IconButton>
        </Grid>
      </Grid>
    </div>
  )
}

FileDownload.propTypes = {}
FileDownload.defaultProps = {}

export default FileDownload
