import React from "react";
import { Link } from "react-router-dom";
import { makeStyles, styled } from "@material-ui/core/styles";

import Typography from "components/Typography";
import KadestoneLogo from "assets/images/kadestone-logo.png";
import { colors } from "theme";

const elevatedComponentStyles = {
  position: "relative",
  zIndex: 300,
};

const ElevatedIcon = styled("img")(elevatedComponentStyles);

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  logo: {
    width: 154,
    "@media (max-width: 768px)": {
      width: 76,
    },
  },
  description: {
    color: colors.textDescription,
    lineHeight: 2,
    marginTop: 10,
    "@media (max-width: 768px)": {
      display: "none",
    },
  },
  description2: {
    color: colors.textDescription,
    lineHeight: 2,
    "@media (max-width: 768px)": {
      display: "none",
    },
  },
});

const Logo = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Link to="/">
        <ElevatedIcon
          src={KadestoneLogo}
          alt="kadestone"
          className={classes.logo}
        />
      </Link>
      <Typography
        size={12}
        variant="body2"
        classes={{ root: classes.description }}
      >
        Suite 3500
      </Typography>
      <Typography
        size={12}
        variant="body2"
        classes={{ root: classes.description2 }}
      >
        The Stack
      </Typography>
      <Typography
        size={12}
        variant="body2"
        classes={{ root: classes.description2 }}
      >
        1133 Melville Street,
      </Typography>
      <Typography
        size={12}
        variant="body2"
        classes={{ root: classes.description2 }}
      >
        Vancouver, BC
      </Typography>
      <Typography
        size={12}
        variant="body2"
        classes={{ root: classes.description2 }}
      >
        V6E 4E5
      </Typography>
    </div>
  );
};

export default Logo;
