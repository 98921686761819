import Landing from '../Pages/Landing'
import DirectorsAndManagement from '../Pages/DirectorsAndManagement'
import Investors from '../Pages/Investors'
import Blog from '../Pages/Blog'

export default () => [
  {
    label: 'Landing',
    path: '/',
    component: Landing,
    exact: true,
  },
  {
    label: 'Directors and Management',
    path: '/directors-and-management',
    component: DirectorsAndManagement,
  },
  {
    label: 'Investors',
    path: '/investors',
    component: Investors,
  },
  {
    label: 'Newsroom',
    path: '/newsroom',
    component: Blog,
  },
]
