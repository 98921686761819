export default [
  { id: 'governance-information', label: 'Governance Information', link: '/governance-information' },
  { id: 'financial-information', label: 'Financial Information', link: '/financial-information' },
  { id: 'share-information', label: 'Share Information', link: '/share-information' },
  { id: 'investors', label: 'Investors', link: '/investors' },
]

export const headerLinks = [
  { id: 'about-us', label: 'About Us', link: '/' },
  { id: 'directors-and-management', label: 'Directors & Management', link: '/directors-and-management' },
  { id: 'newsroom', label: 'Newsroom', link: '/newsroom' },
  { id: 'investors', label: 'Investors', link: '/investors' },
]

export const drawerOptions = [
  { id: 'about-us', label: 'About Us', link: '/' },
  { id: 'directors-and-management', label: 'Directors & Management', link: '/directors-and-management' },
  { id: 'newsroom', label: 'Newsroom', link: '/newsroom' },
  { id: 'investors', label: 'Investors', link: '/investors' },
]
