import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import TextButton from 'components/TextButton'
import Typography from 'components/Typography'

const useStyles = makeStyles({
  link: {
    textDecoration: 'none',
  },
  menuLabel: {
    margin: '0 12px',
    marginTop: 20,
    color: '#707070',
    fontSize: 16,
    '&:hover': {
      color: '#c48a43',
    },
    '@media (max-width: 768px)': {
      marginTop: 5,
      fontSize: 12,
    },
  },
})

const MenuItem = ({ children, link, labelStyles }) => {
  const classes = useStyles()

  return (
    <Link to={link} className={classes.link}>
      <TextButton>
        <Typography className={clsx(classes.menuLabel, labelStyles)}>{children}</Typography>
      </TextButton>
    </Link>
  )
}

export default MenuItem
