import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import Download from '@material-ui/icons/GetApp'
import FileSaver from 'file-saver'

import Typography from 'components/Typography'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 900,
    margin: 'auto',
  },
  document: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: 20,
      backgroundColor: 'white',
      marginBottom: 16,
      fontWeight: 600,
    },
  },
  name: {
    marginLeft: 20,
    fontSize: 24,
    color: '#413f3f',
    flex: 1,
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
      marginLeft: 0,
    },
  },
  separator: {
    borderBottom: '1px solid #d8d8d8',
    marginTop: 16,
    marginBottom: 65,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  download: {
    color: '#c48a43',
  },
  size: {
    color: '#c48a43',
    marginLeft: 20,
    fontFamily: 'Aileron',
    marginRight: 40,
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
      marginLeft: 16,
    },
  },
}))

const AGM = (props) => {
  const classes = useStyles()

  const documents = [
    {
      name: 'Management Information Circular',
      link: '//assets.ctfassets.net/ukstf33kj625/5KVK0uMdstGZDpZYoL8mGi/d86a0b1cf4d8972e837b64394281d62f/Kadestone_-_Management_Information_Circular__2024_AGM_.pdf',
      size: '655 KB',
    }, {
      name: 'Form of Proxy',
      link: '//assets.ctfassets.net/ukstf33kj625/1LSwCgHNpZgEQvufiWJ49l/b3f9f70d21263d3469daff8b52274c08/Kadestone_-_Form_of_Proxy.pdf',
      size: '68 KB',
    }]

  const downloadPdf = (link, title) => {
    FileSaver.saveAs(link, title)
  }

  return (
    <div className={classes.root}>
      {
        documents.map((x) => (
          <Fragment key={x.name}>
            <div className={classes.document}>
              <Typography variant="subtitle1" className={classes.name}>{x.name}</Typography>
              <IconButton onClick={() => downloadPdf(x.link, x.name)}><Download className={classes.download} /></IconButton>
              <Typography className={classes.size}>{x.size}</Typography>
            </div>
            <div className={classes.separator} />
          </Fragment>
        ))
      }
    </div>
  )
}

AGM.propTypes = {}
AGM.defaultProps = {}

export default AGM
