import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import Download from '@material-ui/icons/GetApp'
import FileSaver from 'file-saver'

import Typography from 'components/Typography'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 900,
    margin: 'auto',
  },
  document: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: 20,
      backgroundColor: 'white',
      marginBottom: 16,
      fontWeight: 600,
    },
  },
  name: {
    marginLeft: 20,
    fontSize: 24,
    color: '#413f3f',
    flex: 1,
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
      marginLeft: 0,
    },
  },
  separator: {
    borderBottom: '1px solid #d8d8d8',
    marginTop: 16,
    marginBottom: 65,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  download: {
    color: '#c48a43',
  },
  size: {
    color: '#c48a43',
    marginLeft: 20,
    fontFamily: 'Aileron',
    marginRight: 40,
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
      marginLeft: 16,
    },
  },
}))

const GovernmentDocuments = (props) => {
  const classes = useStyles()

  const documents = [{
    name: 'Board Mandate',
    link: 'https://kadestone-assets.s3-us-west-2.amazonaws.com/Finalize+Board+Mandate+(final+compiled).pdf',
    size: '33.9 KB',
  },
  {
    name: 'Audit Committee Mandate',
    link: 'https://kadestone-assets.s3.us-west-2.amazonaws.com/Kadestone+-+Audit+Committee+Charter+1396-3906-2534+v.2.pdf',
    size: '109 KB',
  },
  {
    name: 'Compensation Committee Mandate',
    link: 'https://kadestone-assets.s3-us-west-2.amazonaws.com/Finalize+Compensation+Committee+Mandate+(final+compiled).pdf',
    size: '10.3 KB',
  },
  {
    name: 'Nominating And Corporate Governance Mandate',
    link: 'https://kadestone-assets.s3-us-west-2.amazonaws.com/Finalize+Nominating+and+Corporate+Governance+Committee+Mandate+(final+compiled).pdf',
    size: '25.8 KB',
  },
  {
    name: 'Code Of Business Conduct',
    link: 'https://kadestone-assets.s3-us-west-2.amazonaws.com/Finalize+Code+of+Business+Conduct+(final+compiled).pdf',
    size: '55.9 KB',
  },
  {
    name: 'Whistleblower Policy',
    link: 'https://kadestone-assets.s3-us-west-2.amazonaws.com/Finalize+Whistleblower+Policy+(final+compiled).pdf',
    size: '27.9 KB',
  }]

  const downloadPdf = (link) => {
    FileSaver.saveAs(link)
  }

  return (
    <div className={classes.root}>
      {
        documents.map((x) => (
          <Fragment key={x.name}>
            <div className={classes.document}>
              <Typography variant="subtitle1" className={classes.name}>{x.name}</Typography>
              <IconButton onClick={() => downloadPdf(x.link)}><Download className={classes.download} /></IconButton>
              <Typography className={classes.size}>{x.size}</Typography>
            </div>
            <div className={classes.separator} />
          </Fragment>
        ))
      }
    </div>
  )
}

GovernmentDocuments.propTypes = {}
GovernmentDocuments.defaultProps = {}

export default GovernmentDocuments
