/**
 * An in-page component to show news articles
 */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Typography from 'components/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import { colors } from 'theme'
import SubscriptionPopup from 'components/SubscriptionPopup'
import { makeStyles } from '@material-ui/core/styles'
import contentful from 'utils/contentful'
import FooterBackground from 'assets/images/FooterBackground.png'
import NewsCard from './NewsCard'
import SwipeableNewsroom from './SwipeableNewsroom'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url(${FooterBackground})`,
    backgroundPosition: 'left center',
    backgroundSize: '40% 100%',
    backgroundRepeat: 'no-repeat',
    height: '100%',
    padding: '68px 48px 32px',
    position: 'relative',
    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      width: '15%',
      height: '100%',
      backgroundImage: `url(${FooterBackground})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      zIndex: -1,
    },
    [theme.breakpoints.down('xs')]: {
      padding: '48px 48px 32px',
      maxHeight: 'unset',
      backgroundSize: 'cover',
      '&:after': {
        display: 'none',
      },
    },
    [theme.breakpoints.down(321)]: {
      padding: 24,
    },
  },
  content: {
    maxWidth: 1004,
    width: '100%',
    margin: '0 auto',
  },
  postsContainer: {
    margin: '40px 0 24px',
  },
  gridItem: {
    '&:before': {
      content: '""',
      paddingTop: '100%',
      float: 'left',
    },
  },
}))

const Newsroom = (props) => {
  const classes = useStyles()
  const [newsPosts, setNewsPosts] = useState([])
  const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('xs'))
  const matchesSm = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  useEffect(() => {
    const fetchNewsPosts = async () => {
      const res = await contentful.getEntries()

      // prettify return object
      setNewsPosts(
        res.items.map((x) => ({
          id: x.sys.id,
          createdAt: x.sys.createdAt,
          ...x.fields,
          heroImage: x.fields.heroImage.fields.file.url,
        })).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)),
      )
    }

    fetchNewsPosts()
  }, [])

  const filteredPosts = matchesSm ? newsPosts.slice(0, 3) : newsPosts.slice(0, 4)

  return (
    <div className={classes.root}>
      <SubscriptionPopup />
      <div className={classes.content}>
        <Typography variant="h2" size={40} color="secondary">Newsroom</Typography>
        {
          matchesXs
            ? <SwipeableNewsroom posts={newsPosts} />
            : (
              <Grid container classes={{ container: classes.postsContainer }} spacing={2}>
                {
                  filteredPosts.map((x) => (
                    <Grid key={x.id} item sm={4} md={3} classes={{ item: classes.gridItem }}>
                      <NewsCard title={x.title} id={x.id} urlPermalink={x.urlPermalink} background={x.heroImage} />
                    </Grid>
                  ))
                }
              </Grid>
            )
        }
        <Link to="/newsroom">
          <Button
            variant="outlined"
            color="secondary"
            size="large"
            endIcon={<ArrowForwardIcon />}
          >
            Read more news
          </Button>
        </Link>
      </div>
    </div>
  )
}

Newsroom.propTypes = {}
Newsroom.defaultProps = {}

export default Newsroom
