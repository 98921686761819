import React from 'react'
import { styled } from '@material-ui/core/styles'

export default styled('div')({
  paddingTop: 40,
  paddingBottom: 180,
  '@media (max-width: 425px)': {
    paddingBottom: 90,
  },
})
