import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { makeStyles, styled } from '@material-ui/core/styles'
import clsx from 'clsx'
import Menu from '@material-ui/icons/Menu'
import IconButton from '@material-ui/core/IconButton'
import Typography from 'components/Typography'
import TextButton from 'components/TextButton'
import Logo from 'assets/images/kadestone-logo.png'
import MenuData, { headerLinks } from './MenuData'
import MobileDrawer from './MobileDrawer'

const elevatedComponentStyles = {
  color: '#707070',
  position: 'relative',
  zIndex: 300,
}

const elevatedColoredComponentStyles = {
  color: '#c48a43',
  position: 'relative',
  zIndex: 300,
}

// elevate logo and menu icon above mobile drawer
const MenuIcon = styled(Menu)(elevatedComponentStyles)
const OpenIcon = styled(Menu)(elevatedColoredComponentStyles)
const ElevatedIcon = styled('img')(elevatedComponentStyles)

const useStyles = makeStyles({
  header: {
    width: '100%',
    backgroundColor: '#fafafa',
    // height: 96,
    position: 'sticky',
    top: 0,
    padding: '20px 10px',
    '@media (max-width: 768px)': {
      padding: '20px 48px',
    },
    '@media (max-width: 425px)': {
      // height: 50,
      padding: '20px 30px',
    },
    zIndex: 9999,
  },
  root: {
    display: 'flex',
    backgroundColor: '#fafafa',
    maxWidth: 1000,
    margin: 'auto',
    height: '100%',
  },
  rootContainer: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: 1200,
    width: 1200,
  },
  menuItems: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    '@media (max-width: 768px)': {
      display: 'none',
    },
  },
  menuLabel: {
    margin: '0 24px',
    // marginTop: 20,
    color: '#707070',
    '&:hover': {
      color: '#c48a43',
    },
  },
  link: {
    textDecoration: 'none',
  },
  moreItemLink: {
    display: 'block',
    marginBottom: 16,
    textDecoration: 'none',
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  moreItemWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  moreMenuItem: {
    cursor: 'default',
    marginLeft: 12,
    marginTop: 20,
    color: '#707070',
    '&:hover': {
      color: '#c48a43',
    },
  },
  moreItemIcon: {
    marginRight: 24,
  },
  moreLabel: {
    color: '#707070',
    '&:hover': {
      color: '#c48a43',
    },
  },
  signInButton: {
    '@media (max-width: 768px)': {
      display: 'none',
    },
  },
  menuIcon: {
    display: 'none',
    '@media (max-width: 768px)': {
      display: 'block',
      marginLeft: 'auto',
      padding: 0,
    },
  },
  separator: {
    marginTop: 15,
    width: '100%',
    borderBottom: '1px solid #cccccd',
  },
  logo: {
    width: 140,
    '@media (max-width: 425px)': {
      width: 100,
    },
  },
  pageblock: ({ isDrawerOpen }) => ({
    display: isDrawerOpen ? 'block' : 'none',
    position: 'absolute',
    zIndex: 300,
    left: 0,
    top: 0,
    height: '100vh',
    width: '100vw',
    backgroundColor: 'black',
    opacity: 0.1,
  }),
})

const MenuItem = ({ children, link }) => {
  const classes = useStyles()

  return (
    <Link to={link} className={classes.link}>
      <TextButton>
        <Typography className={classes.menuLabel} size={16}>{children}</Typography>
      </TextButton>
    </Link>
  )
}

const MoreDropdownContent = () => {
  const classes = useStyles()

  return (
    <div>
      {
        MenuData.map((x, index) => (
          <Link className={classes.moreItemLink} to={x.link}>
            <div className={classes.moreItemWrapper}>
              <TextButton>
                <Typography className={classes.moreLabel} size={16}>{x.label}</Typography>
              </TextButton>
              { index < MenuData.length - 1 && <div className={classes.separator} /> }
            </div>
          </Link>
        ))
      }
    </div>
  )
}

const AppHeader = () => {
  const [isDrawerOpen, toggleDrawer] = useState(false)
  const classes = useStyles({ isDrawerOpen })

  const handleToggleDrawer = () => {
    toggleDrawer(!isDrawerOpen)
    document.body.style.overflowY = !isDrawerOpen ? 'hidden' : 'auto'
  }

  return (
    <div className={classes.header}>
      <div className={classes.root}>
        <div className={classes.rootContainer}>
          <div className={classes.icon}>
            <Link to="/">
              <ElevatedIcon src={Logo} alt="kadestone" className={classes.logo} />
            </Link>
          </div>
          <div className={classes.menuItems}>
            {
              headerLinks.map((x) => <MenuItem key={x.id} link={x.link}>{x.label}</MenuItem>)
            }
          </div>
          <IconButton onClick={handleToggleDrawer} className={classes.menuIcon}>
            {
              isDrawerOpen
                ? <OpenIcon />
                : <MenuIcon />
            }
          </IconButton>
          <div className={classes.pageblock} onClick={handleToggleDrawer} />
          <MobileDrawer open={isDrawerOpen} onClose={handleToggleDrawer} />
        </div>
      </div>
    </div>
  )
}

export default AppHeader
